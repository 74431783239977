import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharingManagementModule } from './sharing-management/sharing-management.module';

const routes: Routes = [


  { path: 'theme-mod', loadChildren: () => import('./theme-mod/theme-mod.module').then(m => m.ThemeModModule) },


  { path: '', loadChildren: () => import('./submission/submission.module').then(m => m.SubmissionModule) },
  { path: 'submission', loadChildren: () => import('./submission/submission.module').then(m => m.SubmissionModule) },


  { path: 'submission-map', loadChildren: () => import('./submission-map/submission-map.module').then(m => m.SubmissionMapModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule,SharingManagementModule]
})
export class AppRoutingModule { }
