<section>
    <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container" >
        <a class="navbar-brand" >ZendusForms</a>
        <form action="#" class="searchform order-sm-start order-lg-last">
      <div class="form-group d-flex right-side-content">
        <!-- <input type="text" class="form-control pl-3" placeholder="Search">
        <button type="submit" placeholder="" class="form-control search"><span class="fa fa-search"></span></button> -->
        <img src="/assets/images/notification-bell.svg" alt=""> &nbsp;&nbsp;
        <img src="/assets/images/out.svg" alt="">
      </div>
    </form>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="fa fa-bars"></span> Menu
      </button>
      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav m-auto">
            <li class="nav-item "><a href="#" class="nav-link"><span><img src="/assets/images/Vector.svg" alt=""></span> &nbsp; Forms</a></li>
            <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Page</a>
          <div class="dropdown-menu" aria-labelledby="dropdown04">
              <a class="dropdown-item" href="#">Page 1</a>
            <a class="dropdown-item" href="#">Page 2</a>
            <a class="dropdown-item" href="#">Page 3</a>
            <a class="dropdown-item" href="#">Page 4</a>
          </div>
        </li> -->
            <li class="nav-item"><a href="#" class="nav-link"><span><img src="/assets/images/people.svg" alt=""></span> &nbsp;Customers</a></li>
            <li class="nav-item active"><a href="#" class="nav-link"><span><img src="/assets/images/submis.svg" alt=""></span> &nbsp;Submissions</a></li>
          <li class="nav-item"><a href="#" class="nav-link"> <span><img src="/assets/images/history.svg" alt=""></span> &nbsp;History</a></li>
          <li class="nav-item"><a href="#" class="nav-link"><span><img src="/assets/images/report.svg" alt=""></span> &nbsp;Reports</a></li>
          <li class="nav-item"><a href="#" class="nav-link"><span><img src="/assets/images/report.svg" alt=""></span> &nbsp;Workflow</a></li>
        </ul>
      </div>
    </div>
  </nav>
<!-- END nav -->

</section>