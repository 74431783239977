import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharingManagementRoutingModule } from './sharing-management-routing.module';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';


@NgModule({
  declarations: [HeaderComponent, LayoutComponent],
  imports: [
    CommonModule,
    SharingManagementRoutingModule
  ],
  exports:[
    HeaderComponent,
    LayoutComponent
  ]
})
export class SharingManagementModule { }
